/* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/no-empty-function */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import {
    ChangeDataType,
    CurrentUserStatus,
    IClientBlockStatus,
    ProfileStatus,
    TAgreementData,
    UserRolesByScope,
} from '@dltru/dfa-models'

export interface IAuthState {
    isAuth: boolean
    isLoading: boolean
    user?: string
    role?: UserRolesByScope
    uuid?: string
    email?: string
    error?: string
    phone?: string
    status: CurrentUserStatus | null
    permissions: ['']
    profileStatus: ProfileStatus | null
    isprofileLoaded?: boolean
    currentFullyClientBlock: boolean
    session_time_out: number | null
    agreements?: TAgreementData
    isFirstTimeLogin: boolean
}

const initialState = {
    isAuth: false,
    isLoading: true,
    profileStatus: null,
    isprofileLoaded: false,
    currentFullyClientBlock: false,
    isBlocked: null,
    session_time_out: null,
    status: null,
    permissions: [''],
    agreements: {
        user_agreement: null,
        rules_agreement: null,
        risk_agreement: null,
        personal_data_agreement: null,
        confidentiality_agreement: null,
    },
    isFirstTimeLogin: false,
} as IAuthState

export namespace AuthNS {
    export type SetBlockingStatus = PayloadAction<Omit<IClientBlockStatus, 'type'>>
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getAuthState: (state) => {
            return state
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setRole: (state, action) => {
            const roleData = action.payload
            return { ...state, ...roleData }
        },
        updateAuth(state, action) {
            const authData = action.payload
            return { ...state, ...authData }
        },
        setAuthError(state, action) {
            state.error = action.payload
        },
        setCurrentClientIsFullyBlocked(state) {
            state.currentFullyClientBlock = true
        },
        setCurrentClientIsUnblocked(state) {
            state.currentFullyClientBlock = false
        },
        dropAuth() {
            return initialState
        },
        setAgreements(state, action) {
            state.agreements = action.payload
        },
        updateAgreements(state, action) {
            state.agreements = { ...state.agreements, ...action.payload }
        },
        dropAgreements(state) {
            state.agreements = {
                user_agreement: null,
                rules_agreement: null,
                risk_agreement: null,
                personal_data_agreement: null,
                confidentiality_agreement: null,
            }
        },
        setIsprofileLoaded(state, action) {
            state.isprofileLoaded = action.payload
        },
    },
})
type SendAgreementsType = {
    data: Partial<TAgreementData>
    callback?: () => void
}
export const sendAgreements = createAction<SendAgreementsType>('auth/sendAgreements')
export const getCurrentUserAction = createAction('currentUser/getCurrentUser1')
export const logout = createAction('auth/logout')
export const resetUserDataAction = createAction<ChangeDataType>('auth/resetUserData')
export const checkProfileStatusAction = createAction<string>('auth/checkProfileStatus')

export const {
    updateAuth,
    getAuthState,
    setAuthError,
    setRole,
    dropAuth,
    setCurrentClientIsFullyBlocked,
    setCurrentClientIsUnblocked,
    setLoading,
    setAgreements,
    updateAgreements,
    dropAgreements,
    setIsprofileLoaded,
} = authSlice.actions

export default authSlice.reducer
