import dayjs from 'dayjs'
import { StrictEffect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { ChangeDataType, CurrentUserStatus, TAgreementData } from '@dltru/dfa-models'
import { calcMainProfileStatus, openMessage } from '@dltru/dfa-ui'

import { modifyLsByKey } from '@utils/localStorage'

import {
    IAuthState,
    logout as logoutAction,
    resetUserDataAction,
    setAgreements,
    setIsprofileLoaded,
    setLoading,
} from '@store/auth/index'
import { authSelector } from '@store/auth/selectors'
import { certificateSlice } from '@store/certificates'
import { profileDetailsSlice } from '@store/profiles/details'
import { roleFormsSlice } from '@store/roleForms'

import api from '@services/api'

import { dropAuth, getCurrentUserAction, sendAgreements, updateAuth } from './index'

function* checkProfileStatus(uuid: string) {
    try {
        yield* put(setIsprofileLoaded(false))

        const { data, error } = yield* call(api.lib.getFullUser, uuid)

        if (error || data?.error) {
            throw error || data?.error
        }

        if (data?.item) {
            yield* put(roleFormsSlice.actions.setProfile(data.item))

            const status =
                data.item.profile_data.individual?.profile_status ||
                data.item.profile_data.businessman?.profile_status ||
                data.item.profile_data.legal_entity?.profile_status

            if (!status) {
                throw new Error('no profile')
            }

            const profileStatus = calcMainProfileStatus(
                status,
                data.item.profile_data.agents?.[0]?.application_status,
            )

            yield* put(updateAuth({ profileStatus }))
            modifyLsByKey('user', { profileStatus })
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
    } finally {
        yield* put(setIsprofileLoaded(true))
    }
}

function* getCurrentUser() {
    try {
        yield* put(setLoading(true))

        // TODO надо сравнить сохраненные вариант и то что полуим и как то пользователю об этом сказать и выпольнить редирект
        // временно отключил ASTimonovskiy 01-12-2023
        /*const storedUser = localStorage.getItem('user')
        if (storedUser) {
            const user = JSON.parse(storedUser) as IAuthState

            yield put(
                updateAuth({
                    ...user,
                    isLoading: false,
                    isAuth: true,
                }),
            )
        }*/

        // get users data with status from Profile
        const { data, error } = yield* call(api.lib.getCurrentUser)

        if (error) {
            throw error
        }

        if (!data) {
            throw new Error('NO_CURRENT_USER_DATA')
        }

        const uuid = data.item.subject_id
        const status = data.item.status

        // get agreements stauses
        const { data: agreementsData, error: agreemntsError } = yield* call(
            api.lib.getAgreementsServiceByUser,
            uuid,
        )

        if (agreemntsError) {
            throw error
        }

        // костыль, информаци о первом логине должна приходить с бека, опираться на LS не ок, данные могут быть изменены или повреждены
        const storedStatus = localStorage.getItem(`status-${uuid}`)
        let isFirstTimeLogin = false
        if (storedStatus) {
            isFirstTimeLogin =
                storedStatus === CurrentUserStatus.UNREGISTERED &&
                status !== CurrentUserStatus.UNREGISTERED
        } else if (
            agreementsData?.item.risk_agreement === 0 &&
            agreementsData?.item.rules_agreement === 0 &&
            agreementsData?.item.user_agreement === 0 &&
            status !== CurrentUserStatus.UNREGISTERED
        ) {
            isFirstTimeLogin = true
        }

        localStorage.setItem(`status-${uuid}`, status)
        // end of kostыль

        yield put(setAgreements(agreementsData?.item))

        yield put(
            updateAuth({
                isLoading: false,
                isAuth: true,
                permissions: data.item.permissions,
                uuid: data.item.subject_id,
                status,
                isFirstTimeLogin,
            }),
        )

        // get extended auth data
        const { data: authData, error: authError } = yield* call(api.lib.fetchAuthData, uuid)

        if (authError) {
            throw error
        }

        yield* put(
            updateAuth({
                ...authData?.item,
                user: authData?.item.login,
            }),
        )

        const newAuthData = yield* select(authSelector.selectAuthData)
        localStorage.setItem('user', JSON.stringify(newAuthData))

        if (data.item.user.type) {
            yield call(checkProfileStatus, uuid)
        } else {
            yield* put(setIsprofileLoaded(true))
        }
    } catch (error) {
        yield* put(
            updateAuth({
                error: true,
                isAuth: false,
                isLoading: false,
            }),
        )

        localStorage.removeItem('user')

        if (error.response && error.response.status === 401) {
            return
        }

        //const encodedUrl = encodeURIComponent(window.location.href)
        // window.location.replace(`/web-auth/start?rd=${encodedUrl}`)
        yield call(handleLogout)
    }
}

function* handleSendAgreements({ payload }: ReturnType<typeof sendAgreements>) {
    try {
        const { data: agreementsVersions, error: adminAgreemntsError } = yield* call(
            api.lib.getAgreementsByAdminService,
        )

        if (adminAgreemntsError) {
            throw adminAgreemntsError
        }

        if (!agreementsVersions) {
            throw new Error('get-agreements-error')
        }

        const data = Object.keys(payload.data).reduce((acc, curr) => {
            const key = curr as keyof TAgreementData
            acc[curr] = dayjs(agreementsVersions.item[key]).unix()
            return acc
        }, {} as Record<string, number>)

        const { error } = yield* call(api.lib.setAgreementsService, {
            ...data,
        })

        if (error) {
            throw error
        }
        yield* put(updateAuth({ isLoading: false }))

        payload.callback?.()
    } catch (error) {
        openMessage({
            type: 'error',
            message: 'Возникли ошибки при отправке согласий',
        })
        yield* put(updateAuth({ error: error, isLoading: false }))
    }
}

function* handleLogout() {
    try {
        localStorage.removeItem('user')
        yield* put(profileDetailsSlice.actions.clearProfile())
        yield* put(roleFormsSlice.actions.clear())
        yield* put(certificateSlice.actions.clearData())
        yield* put(dropAuth())

        yield handleRedirect()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
    }
}

function* handleRedirect() {
    const redirectUrl = encodeURIComponent(
        `${window.location.origin}/web-auth/sign_out?rd=${window.location.href}`,
    )
    window.location.replace(
        `/id/realms/clients/protocol/openid-connect/logout?client_id=dfa-web-client&post_logout_redirect_uri=${redirectUrl}`,
    )
}

function* handleResetUserData({ payload }: ReturnType<typeof resetUserDataAction>) {
    let apiCallback = api.lib.changeInitPasswordReset
    let errorPrefix = 'пароля'
    if (payload === ChangeDataType.PHONE) {
        apiCallback = api.lib.changeInitPhoneReset
        errorPrefix = 'телефона'
    } else if (payload === ChangeDataType.EMAIL) {
        apiCallback = api.lib.changeInitEmailReset
        errorPrefix = 'почты'
    }
    try {
        const { error, data } = yield* call(apiCallback)

        if (error) {
            throw error
        } else if (data) {
            new URL(data.profile_data_reset_url)
            window.location.href = data.profile_data_reset_url
        }
    } catch (error) {
        openMessage({
            type: 'error',
            message: `Возникли ошибки при изменении ${errorPrefix}`,
        })
        yield* put(updateAuth({ error: error }))
    }
}

export function* watchAuth(): Generator<StrictEffect> {
    yield* takeLatest(sendAgreements.type, handleSendAgreements)
    yield* takeLatest(getCurrentUserAction.type, getCurrentUser)
    yield* takeLatest(logoutAction.type, handleLogout)
    yield* takeLatest(resetUserDataAction.type, handleResetUserData)
}
