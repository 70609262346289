import { createSelector } from '@reduxjs/toolkit'

import { CurrentUserStatus, ProfileStatus } from '@dltru/dfa-models'

import IAppState from '../states'

const selectUserUid = (state: IAppState) => state.auth.uuid
const selectAuthData = (state: IAppState) => state.auth
const selectProfileStatus = (state: IAppState) => state.auth.profileStatus
const isEmptyProfile = (state: IAppState) => state.auth.profileStatus !== ProfileStatus.APPROVED
const isFullyBlocked = (state: IAppState) => state.auth.currentFullyClientBlock
const selectUserName = (state: IAppState) => state.auth.user
const selectIsApproved = createSelector(
    (state: IAppState) => state.auth.profileStatus,
    (profileStatus) => profileStatus === ProfileStatus.APPROVED,
)
const selectIsOnboardingAgreements = createSelector(
    (state: IAppState) => state.auth.status,
    (status) => status === CurrentUserStatus.ONBOARDING_AGREEMENTS,
)
const selectIsAuth = (state: IAppState) => state.auth.isAuth
const selectSessionTimeout = (state: IAppState) => state.auth.session_time_out || 30 // cookie_expire = "30m"
const selectPermissions = (state: IAppState) => state.auth.permissions
const selectStatus = (state: IAppState) => state.auth.status
const selectIsLoading = (state: IAppState) => state.auth.isLoading
const selectIsFirstTimeLogin = (state: IAppState) => state.auth.isFirstTimeLogin

const selectAgreements = (state: IAppState) => state.auth.agreements

const isAllAgreementsSelector = createSelector(
    selectAgreements,
    selectStatus,
    (agreements, status) => {
        if (status === CurrentUserStatus.UNREGISTERED) {
            return true
        }

        return Boolean(
            agreements?.risk_agreement &&
                agreements?.rules_agreement &&
                agreements?.user_agreement &&
                agreements?.confidentiality_agreement,
        )
    },
)

const isUserRegistredSelector = createSelector(selectStatus, (status) => {
    if (!status) {
        return false
    }

    return [
        CurrentUserStatus.REGISTERED,
        CurrentUserStatus.ONBOARDING_AGREEMENTS,
        CurrentUserStatus.ONBOARDING_PROVISIONING,
    ].includes(status)
})

export const authSelector = {
    selectUserUid,
    isEmptyProfile,
    selectProfileStatus,
    isFullyBlocked,
    selectAuthData,
    selectIsApproved,
    selectIsAuth,
    selectUserName,
    selectSessionTimeout,
    selectPermissions,
    selectStatus,
    selectIsLoading,
    selectIsOnboardingAgreements,
    selectAgreements,
    isAllAgreementsSelector,
    isUserRegistredSelector,
    selectIsFirstTimeLogin,
}
